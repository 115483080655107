import Vue from 'vue'
import { EagleListConfigInterface, TableDataType } from '@/components/list/types/list'
import prettyBytes from 'pretty-bytes'
class listConfig {
  protected vm?: Vue
  protected listKey?: string

  get(vm: Vue, listKey: string) : EagleListConfigInterface {
    this.vm = vm
    this.listKey = listKey

    return {
      pageTitle: 'file_manager',
      search: 'file.search_keyword',
      filter: {
        type: {
          label: 'file.data.type',
          type: 'text',
        },
        extension: {
          label: 'file.data.extension',
          type: 'text',
        },
        created_at: {
          label: 'data.created_at',
          type: 'date',
        },
      },
      selectedData: row => ({
        id: row.id,
        filename: row.filename,
        file: row,
      }),
      sort: [
        { label: 'data.created_at', key: 'created_at' },
        { label: 'data.file_size', key: 'size' },
      ],
      table: {
        data: <TableDataType>[
          {
            key: 'file',
            label: '',
            type: 'photo',
            placeholderIcon: 'fa fa-file-archive',
            photo: row => {
              const isImage = new RegExp(/image/g).test(row.file_type)
              if(!isImage) return null
              if(!row.path) return null
              return {
                // @ts-ignore
                url: `${this.vm.fileBaseUrl}${row.path}`,
              }
            },
          },
          {
            key: 'filename',
            label: 'file.data.filename',
            copyable: true,
            type: 'text',
          },
          {
            key: 'type',
            label: 'file.data.type',
            type: 'text',
          },
          {
            key: 'size',
            label: 'data.file_size',
            type: 'text',
            text: row => prettyBytes(row.size),
          },
          {
            key: 'created_at',
            label: 'data.created_at',
            type: 'time',
          },
        ],
      },
      batch: {
        delete: {
          targetLabel: row => row.filename,
        },
      },
      dataAction: {
        download: {
          component: () => import('modules/base/views/fileList/download.vue'),
        },
        copyUrl: {
          component: () => import('modules/base/views/fileList/copyUrl.vue'),
        },
      },
    }

  }
}

export default new listConfig()
